import React from "react";

import { Box, CircularProgress, Paper } from "@mui/material";

export const Loader: React.FC = () => {
  return (
    <Paper
      variant="outlined"
      style={{ padding: 40 }}
      sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress color={"primary"} />
      </Box>
    </Paper>
  );
};
