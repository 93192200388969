import React from "react";

import { Home } from "./pages/Home";

import CSSBaseline from "@mui/material/CssBaseline";

import { themeOptions } from "./theme/theme";

import { FormProvider } from "./contexts/FormContext";
import { FirestoreProvider } from "./contexts/FirestoreContext";

import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme(themeOptions);
const useStyles = makeStyles({
  content: {
    backgroundColor: "#003C71",
    minHeight: "170vh",
  },
});
export const App: React.FC = () => {
  const classes = useStyles();
  return (
    <AppState>
      <ThemeProvider theme={theme}>
        <div className={classes.content}>
          <Home />
          <CSSBaseline />
        </div>
      </ThemeProvider>
    </AppState>
  );
};

const AppState = ({ children }: { children: JSX.Element }) => {
  return (
    <FirestoreProvider>
      <FormProvider>{children}</FormProvider>
    </FirestoreProvider>
  );
};
