import React, { useContext } from "react";

import { Box, Typography } from "@mui/material";

import { CheckCircle } from "@mui/icons-material";

import { SuccessActions } from "../actions/SuccessActions";

import { FormContext } from "../../contexts/FormContext";
import { FirestoreContext } from "../../contexts/FirestoreContext";

type SuccessProps = {
  onHandleReset: () => void;
};

export const Success: React.FC<SuccessProps> = ({ onHandleReset }) => {
  const context = useContext(FormContext);
  const firestore = useContext(FirestoreContext);

  const onHandleClick = (): void => {
    context.reset();
    onHandleReset();
    context.onHandleFile(null);
    context.onHandleAddress(null);
    context.onHandleResetSpecialties();
    firestore.onHandleCurrentElement(null);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CheckCircle sx={{ width: 82, height: 82 }} color={"success"} />
        <Typography variant="h5" gutterBottom>
          {"Registro exitoso."}
        </Typography>
      </Box>
      <SuccessActions onClick={onHandleClick} />
    </Box>
  );
};
