export const isValidName = (value: string): boolean => {
  const result = /^([A-Za-zÀ-ÿ ]){2,30}$/.test(value);
  return result;
};

export const isValidRFC = (value: string): boolean => {
  const result =
    /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/.test(
      value
    );
  return result;
};

export const isValidObjectName = (value: string): boolean => {
  const result = /^[A-Za-zÀ-ÿ0-9 ]+$/i.test(value);
  return result;
};

export const capitalize = (str: string, lower = false): string =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );
