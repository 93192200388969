import React, { useContext, useEffect } from "react";

import {
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";

import { StepperProps } from "../../models/stepper";

import { FormContext } from "../../contexts/FormContext";

export const Fields: React.FC<StepperProps> = ({ onDisabled }) => {
  const { onHandleToggleSerie, onHandleToggleBase, serie, base } =
    useContext(FormContext);

  useEffect(() => onDisabled(isFormValid()), [serie, base, onDisabled]);

  const handleToggleSerie = (value: string) => () => {
    const currentIndex = serie.indexOf(value);
    const newChecked = [...serie];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    onHandleToggleSerie(newChecked);
  };
  const handleToggleBase = (value: string) => () => {
    const currentIndex = base.indexOf(value);
    const newChecked = [...base];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    onHandleToggleBase(newChecked);
  };

  const isFormValid = (): boolean => {
    if (serie.length === 0) {
      return false;
    } else if (base.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <Grid container spacing={2} marginTop={1}>
      <Grid item xs={6}>
        <Typography color={"primary"} fontWeight={"bold"} fontSize={18}>
          Serie *
        </Typography>
        {["100", "200", "300", "400"].map((value) => (
          <ListItem
            key={value}
            disablePadding
            style={{
              border: "1px solid #e8eaf6",
              borderRadius: 15,
              marginTop: 10,
            }}
          >
            <ListItemButton
              dense
              role={undefined}
              onClick={handleToggleSerie(value)}
            >
              <ListItemIcon>
                <Radio
                  size="small"
                  checked={serie.indexOf(value) !== -1}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                id={value}
                primary={
                  <Typography fontSize={12} fontWeight={"bold"}>
                    {value}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </Grid>
      <Grid item xs={6}>
        <Typography color={"primary"} fontWeight={"bold"} fontSize={18}>
          Base *
        </Typography>
        {["A", "AA", "0", "2000"].map((value) => (
          <ListItem
            key={value}
            disablePadding
            style={{
              border: "1px solid #e8eaf6",
              borderRadius: 15,
              marginTop: 10,
            }}
          >
            <ListItemButton
              dense
              role={undefined}
              onClick={handleToggleBase(value)}
            >
              <ListItemIcon>
                <Radio
                  size="small"
                  checked={base.indexOf(value) !== -1}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                id={value}
                primary={
                  <Typography fontSize={12} fontWeight={"bold"}>
                    {value}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </Grid>
    </Grid>
  );
};
