import React, { useContext, useState } from "react";

import {
  Step,
  Paper,
  Stepper,
  StepLabel,
  Container,
  Typography,
  useMediaQuery,
  Theme,
  Grid,
  Box,
} from "@mui/material";

import { Loader } from "../components/loading/Loader";
import { Success } from "../components/steps/Success";
import { StepperForm } from "../components/forms/StepperForm";
import { FirestoreContext } from "../contexts/FirestoreContext";
import { ColorlibStepIcon } from "../theme/stepperIconsStyles";

import Logo from "../assets/images/Logo.png";
import Background from "../assets/images/background.png";
import { Footer } from "../components/navigation/Footer";

const steps = [
  "Información Personal",
  "Nivel Hospitalario",
  "Especialidades",
  "Dirección",
  "Contacto",
];

export const Home: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const {
    state: { loading },
  } = useContext(FirestoreContext);
  const tablet = useMediaQuery(({ breakpoints }: Theme) =>
    breakpoints.down("md")
  );
  const phone = useMediaQuery(({ breakpoints }: Theme) =>
    breakpoints.down("sm")
  );

  const onHandleReset = (): void => setStep(0);

  const onHandleNext = (): void => setStep(step + 1);

  const onHandleBack = (): void => setStep(step - 1);

  const onHandleRender = (): JSX.Element => {
    if (step === steps.length) {
      return <Success onHandleReset={onHandleReset} />;
    }
    return (
      <StepperForm
        step={step}
        onHandleBack={onHandleBack}
        onHandleNext={onHandleNext}
      />
    );
  };

  return (
    <Container component="main" maxWidth="lg" style={{ padding: 20 }}>
      <Box
        component={"img"}
        src={Background}
        sx={{
          height: 300,
          position: "absolute",
          left: phone ? "40%" : tablet ? "70%" : "12%",
        }}
        style={{ objectFit: "contain" }}
        alt={Background}
        zIndex={0}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box
            component={"img"}
            src={Logo}
            sx={{
              height: 60,
            }}
            style={{ objectFit: "contain" }}
            alt={Logo}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3} md={3}>
          <Typography
            component="h1"
            fontSize={25}
            fontWeight={700}
            align="left"
            color={"white"}
          >
            Base Hospitalaria
          </Typography>
          <Box
            style={{
              width: 30,
              height: 3,
              backgroundColor: "#00AE42",
              borderRadius: 10,
            }}
          />
          <Stepper
            activeStep={step}
            sx={{
              pt: 3,
              pb: {
                xs: 0,
                sm: 0,
                md: 6,
              },
            }}
            orientation={"vertical"}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <Typography color={"white"}>{label}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} sm={12} lg={9} md={9} zIndex={1}>
          {loading ? (
            <Loader />
          ) : (
            <Paper
              variant="outlined"
              style={{ padding: 40 }}
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              {onHandleRender()}
            </Paper>
          )}
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};
