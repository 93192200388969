import React, { useContext } from "react";

import { Box, Button, CircularProgress } from "@mui/material";

import { FormContext } from "../../contexts/FormContext";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type StepperActionsProps = {
  step: number;
  loading: boolean;
  disabled: boolean;
  onHandleBack: () => void;
  onHandleNext: () => void;
};

export const StepperActions: React.FC<StepperActionsProps> = ({
  step,
  loading,
  disabled,
  onHandleBack,
  onHandleNext,
}) => {
  const {
    formState: { type },
  } = useContext(FormContext);
  return (
    <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
      {!loading && step !== 0 && (
        <Button
          onClick={onHandleBack}
          sx={{ mt: 3, ml: 1 }}
          variant="outlined"
          size="large"
          style={{ textTransform: "none", borderRadius: 30 }}
        >
          Volver
        </Button>
      )}
      {!loading ? (
        <Button
          disabled={disabled}
          variant="contained"
          onClick={onHandleNext}
          sx={{ mt: 3, ml: 1 }}
          endIcon={<ArrowForwardIcon />}
          style={{ borderRadius: 30, textTransform: "none" }}
        >
          {step === 4
            ? `Guardar ${type === "Hospital" ? "hospital" : "médico"}`
            : "Siguiente"}
        </Button>
      ) : (
        <Button
          disabled
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          style={{ borderRadius: 30, textTransform: "none" }}
        >
          <CircularProgress color={"inherit"} size={24} />
        </Button>
      )}
    </Box>
  );
};
