import React from "react";

import { styled, TextField } from "@mui/material";

type InputProps = {
  type?: string;
  name?: string;
  value: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  endAdornment?: React.ReactNode | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  color?: "error" | "primary" | "secondary" | "info" | "success" | "warning";
};

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "10px",
  },
}));

export const Input: React.FC<InputProps> = ({
  name,
  value,
  onChange,
  label = "",
  type = "text",
  disabled = false,
  required = false,
  color = "primary",
  endAdornment = undefined,
}) => {
  return (
    <CustomTextField
      fullWidth
      size="small"
      type={type}
      name={name}
      value={value}
      label={label}
      color={color}
      required={required}
      disabled={disabled}
      onChange={onChange}
      variant={"outlined"}
      InputProps={{ endAdornment: endAdornment }}
    />
  );
};
