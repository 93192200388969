import React, { createContext, useState } from "react";

import { SelectChangeEvent } from "@mui/material";

import { useForm } from "../hooks/useForm";

import { Specialty } from "../models/specialty";
import { Direction } from "../models/direction";

export interface FormState {
  [key: string]: string;
  key: string;
  type: string;
  name: string;
  prefix: string;
  lastName: string;
  zip: string;
  street: string;
  outdoorNumber: string;
  indoorNumber: string;
  neighborhood: string;
  email: string;
  phoneNumber: string;
}

const initialState: FormState = {
  type: "",
  name: "",
  key: "",
  prefix: "",
  lastName: "",
  zip: "",
  street: "",
  outdoorNumber: "",
  indoorNumber: "",
  neighborhood: "",
  email: "",
  phoneNumber: "",
};

export interface FormContextProps {
  file: File | null;
  reset: () => void;
  formState: FormState;
  specialties: Specialty[];
  serie: string[];
  base: string[];
  onHandleToggleSerie: (value: string[]) => void;
  onHandleToggleBase: (value: string[]) => void;
  address: Direction | null;
  onHandleResetSpecialties: () => void;
  onHandleFile: (file: File | null) => void;
  onHandleToggle: (specialty: Specialty) => void;
  onHandleAddress: (address: Direction | null) => void;
  setFormState: (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => void;
}

export const FormContext = createContext({} as FormContextProps);

export const FormProvider = ({ children }: any) => {
  const [file, setFile] = useState<File | null>(null);
  const [address, setAddress] = useState<Direction | null>(null);
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [serie, setSerie] = useState<string[]>([]);
  const [base, setBase] = useState<string[]>([]);
  const [formState, setFormState, reset] = useForm<FormState>(initialState);

  const onHandleFile = (file: File | null): void => setFile(file);

  const onHandleToggleSerie = (value: string[]) => {
    setSerie(value);
  };
  const onHandleToggleBase = (value: string[]) => {
    setBase(value);
  };

  const onHandleToggle = (value: Specialty): void => {
    if (specialties.some((el) => el.uid === value.uid)) {
      setSpecialties(
        specialties.filter((specialty) => specialty.uid !== value.uid)
      );
    } else {
      setSpecialties([value, ...specialties]);
    }
  };

  const onHandleAddress = (value: Direction | null): void => {
    setAddress(value);
  };

  const onHandleResetSpecialties = (): void => setSpecialties([]);

  return (
    <FormContext.Provider
      value={{
        file,
        reset,
        address,
        formState,
        specialties,
        serie,
        base,
        onHandleToggleSerie,
        onHandleToggleBase,
        onHandleFile,
        setFormState,
        onHandleToggle,
        onHandleAddress,
        onHandleResetSpecialties,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
