import React, { useEffect, useContext } from "react";

import { Button, Grid } from "@mui/material";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { Input } from "../inputs/Input";
import { Select } from "../inputs/Select";

import {
  isValidRFC,
  isValidName,
  isValidObjectName,
} from "../../helpers/regex";

import { StepperProps } from "../../models/stepper";

import { FormContext } from "../../contexts/FormContext";

import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const Disabled = styled("input")({
  display: "none",
});

const useStyles = makeStyles({
  icons: {
    width: 11,
    color: "#BCC3CC",
  },
});

export const Information: React.FC<StepperProps> = ({ onDisabled }) => {
  const classes = useStyles();

  const context = useContext(FormContext);

  const { file, formState, setFormState, onHandleFile } = context;

  const { type } = formState;

  useEffect(() => onDisabled(isFormValid()), [formState, onDisabled]);

  const label = (key: string): string => {
    if (key === "type") {
      return "Tipo";
    } else if (key === "key") {
      return "RFC*";
    } else if (key === "lastName") {
      return "Apellidos*";
    } else if (key === "prefix") {
      return "Prefijo";
    }
    return type === "Hospital" ? "Nombre del proveedor*" : "Nombre*";
  };

  const isFormValid = (): boolean => {
    const { type, name, lastName, key, prefix } = formState;
    if (type === "Hospital" || type === "Laboratorio") {
      if (name === "" || !isValidObjectName(name)) {
        return false;
      } else if (!isValidRFC(key)) {
        return false;
      }
    } else if (type === "Médico") {
      if (name === "" || !isValidName(name)) {
        return false;
      } else if (lastName === "" || !isValidName(lastName)) {
        return false;
      } else if (!isValidRFC(key)) {
        return false;
      } else if (prefix === "") {
        return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const color = (
    key: string
  ): "error" | "primary" | "secondary" | "info" | "success" | "warning" => {
    if (key === "name") {
      if (type === "Hospital") {
        if (formState[key] === "" || !isValidObjectName(formState[key])) {
          return "error";
        } else {
          return "success";
        }
      } else if (type === "Médico") {
        if (formState[key] === "" || !isValidName(formState[key])) {
          return "error";
        } else {
          return "success";
        }
      } else {
        return "primary";
      }
    } else if (key === "lastName") {
      if (formState[key] === "" || !isValidName(formState[key])) {
        return "error";
      } else {
        return "success";
      }
    } else if (key === "key") {
      if (!isValidRFC(formState[key])) {
        return "error";
      } else {
        return "success";
      }
    }
    return "primary";
  };

  return (
    <Grid container spacing={2} marginTop={1}>
      {Object.keys((({ type }) => ({ type }))(formState)).map((key, i) => (
        <Grid key={i} item xs={12} sm={key === "type" ? 12 : 6}>
          <Select
            name={key}
            label={label(key)}
            value={formState[key]}
            items={["Hospital", "Laboratorio", "Médico"]}
            onChange={(e) => setFormState(e)}
          />
        </Grid>
      ))}
      {type === "Hospital" || type === "Laboratorio"
        ? Object.keys((({ name, key }) => ({ name, key }))(formState)).map(
            (key, i) => (
              <Grid key={i} item xs={12}>
                <Input
                  name={key}
                  color={color(key)}
                  label={label(key)}
                  value={formState[key]}
                  onChange={(e) => setFormState(e)}
                />
              </Grid>
            )
          )
        : type === "Médico"
        ? Object.keys(
            (({ prefix, key, name, lastName }) => ({
              prefix,
              key,
              name,
              lastName,
            }))(formState)
          ).map((key, i) => (
            <Grid key={i} item xs={12} sm={6}>
              {key === "prefix" ? (
                <Select
                  name={key}
                  label={label(key)}
                  value={formState[key]}
                  items={["Dr.", "Dra."]}
                  onChange={(e) => setFormState(e)}
                />
              ) : (
                <Input
                  name={key}
                  color={color(key)}
                  label={label(key)}
                  value={formState[key]}
                  onChange={(e) => setFormState(e)}
                />
              )}
            </Grid>
          ))
        : null}
      <Grid item xs={12}>
        <label htmlFor="file">
          <Disabled
            id="file"
            type="file"
            accept="image/*"
            multiple={false}
            onChange={(e) => onHandleFile(e.target.files![0])}
          />
          <Button
            variant="outlined"
            component="span"
            size="large"
            startIcon={<AddAPhotoOutlinedIcon className={classes.icons} />}
            endIcon={<FileUploadOutlinedIcon className={classes.icons} />}
            style={{
              border: " 1px dashed #ABABAB",
              borderRadius: 10,
              textTransform: "none",
              fontSize: 12,
            }}
          >
            {file ? file.name : "Seleccionar imagen"}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};
