import firebase from "firebase/app";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyAeGZW3TmqUDi6GgOeT-MLl5HipvGggQOk",
  authDomain: "planseguro-a7d6f.firebaseapp.com",
  projectId: "planseguro-a7d6f",
  storageBucket: "planseguro-a7d6f.appspot.com",
  messagingSenderId: "368572865611",
  appId: "1:368572865611:web:0e279658d9e7b688b9c1ce",
  measurementId: "G-FLBKMY9P5M",
};

firebase.initializeApp(config);

const db = firebase.firestore();

export { db };
