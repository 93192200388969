import { createContext, useState, useEffect } from "react";

import { db } from "../config/firebase";

import { Hospital } from "../models/hospital";
import { Specialty } from "../models/specialty";
import {
  HospitalFirestore,
  HospitalSpecialty,
} from "../models/hospital-firestore";
import { Doctor } from "../models/doctor";
import { DoctorFirestore, DoctorSpecialty } from "../models/doctor-firestore";

export interface FirestoreState {
  saved: boolean;
  loading: boolean;
  specialties: Specialty[];
  element: HospitalFirestore | null;
}

const initialState: FirestoreState = {
  saved: false,
  element: null,
  loading: false,
  specialties: [],
};

export interface FirestoreProps {
  state: FirestoreState;
  onHandleSaveDoctor: (
    doctor: Doctor,
    specialties: Specialty[]
  ) => Promise<void>;
  onHandleSaveHospital: (
    hospital: Hospital,
    specialties: Specialty[]
  ) => Promise<void>;
  onHandleCurrentElement: (element: HospitalFirestore | null) => void;
}

export const FirestoreContext = createContext({} as FirestoreProps);

export const FirestoreProvider = ({ children }: any) => {
  const [state, setState] = useState<FirestoreState>(initialState);

  useEffect(() => {
    const onHandleRequestSpecialties = async (): Promise<void> => {
      const data: Specialty[] = [];
      onHandleLoading(true);
      const query = await db.collection("specialties").get();
      query.docs.forEach((doc) => {
        data.push({ ...(doc.data() as Specialty), uid: doc.id });
      });
      onHandleSpecialties(
        data.sort((a, b) =>
          a.name.normalize().localeCompare(b.name.normalize())
        )
      );
      onHandleLoading(false);
    };

    onHandleRequestSpecialties();
  }, []);

  const onHandleSaved = (saved: boolean): void => {
    setState((prev) => ({ ...prev, saved }));
  };

  const onHandleLoading = (loading: boolean): void => {
    setState((prev) => ({ ...prev, loading }));
  };

  const onHandleSpecialties = (specialties: Specialty[]): void => {
    setState((prev) => ({ ...prev, specialties }));
  };

  const onHandleCurrentElement = (element: HospitalFirestore | null): void => {
    setState((prev) => ({ ...prev, element }));
  };

  const onHandleSaveHospital = async (
    hospital: Hospital,
    specialties: Specialty[]
  ): Promise<void> => {
    onHandleSaved(true);
    const data: HospitalSpecialty[] = [];
    try {
      const ref = await db.collection("hospitals").add({ ...hospital });
      const snapshot = await ref.get();
      for await (const specialty of specialties) {
        const element = {
          publishedAt: new Date().toISOString(),
          specialty: db.collection("specialties").doc(specialty.uid),
        };
        const reference = await db
          .collection("hospitals")
          .doc(ref.id)
          .collection("specialties")
          .add(element);
        const snapshot = await reference.get();
        data.push({
          ...(snapshot.data() as HospitalSpecialty),
          uid: snapshot.id,
        });
      }
      const element: HospitalFirestore = {
        ...(snapshot.data() as HospitalFirestore),
        specialties: data,
        uid: snapshot.id,
      };
      onHandleSaved(false);
      onHandleCurrentElement(element);
    } catch (e) {
      onHandleSaved(false);
    }
  };

  const onHandleSaveDoctor = async (
    doctor: Doctor,
    specialties: Specialty[]
  ): Promise<void> => {
    onHandleSaved(true);
    const data: DoctorSpecialty[] = [];
    try {
      const ref = await db.collection("doctors").add({ ...doctor });
      const snapshot = await ref.get();
      for await (const specialty of specialties) {
        const element = {
          publishedAt: new Date().toISOString(),
          specialty: db.collection("specialties").doc(specialty.uid),
        };
        const reference = await db
          .collection("doctors")
          .doc(ref.id)
          .collection("specialties")
          .add(element);
        const snapshot = await reference.get();
        data.push({
          ...(snapshot.data() as DoctorSpecialty),
          uid: snapshot.id,
        });
      }
      const element: DoctorFirestore = {
        ...(snapshot.data() as DoctorFirestore),
        specialties: data,
        uid: snapshot.id,
      };
      onHandleSaved(false);
      onHandleCurrentElement(element);
    } catch (e) {
      onHandleSaved(false);
    }
  };

  return (
    <FirestoreContext.Provider
      value={{
        state,
        onHandleSaveDoctor,
        onHandleSaveHospital,
        onHandleCurrentElement,
      }}
    >
      {children}
    </FirestoreContext.Provider>
  );
};
