import React, { useContext, useEffect } from "react";

import validator from "validator";

import { Grid } from "@mui/material";

import { Input } from "../inputs/Input";

import { StepperProps } from "../../models/stepper";

import { FormContext } from "../../contexts/FormContext";

export const Contact: React.FC<StepperProps> = ({ onDisabled }) => {
  const { formState, setFormState } = useContext(FormContext);

  useEffect(() => onDisabled(isFormValid()), [formState, onDisabled]);

  const isFormValid = (): boolean => {
    const { type, email, phoneNumber } = formState;
    if (type === "Hospital") {
      if (
        phoneNumber.trim() === "" ||
        phoneNumber.length !== 10 ||
        !validator.isMobilePhone(phoneNumber)
      ) {
        return false;
      }
    } else {
      if (email.trim() === "" || !validator.isEmail(email)) {
        return false;
      } else if (
        phoneNumber.trim() === "" ||
        phoneNumber.length !== 10 ||
        !validator.isMobilePhone(phoneNumber)
      ) {
        return false;
      }
    }
    return true;
  };

  const label = (key: string): string => {
    const { type } = formState;
    if (key === "email") {
      return type === "Hospital" ? "Correo electrónico" : "Correo electrónico*";
    }
    return "Teléfono*";
  };

  const color = (
    key: string
  ): "error" | "primary" | "secondary" | "info" | "success" | "warning" => {
    if (key === "email") {
      if (formState[key].trim() === "") {
        return "primary";
      } else if (!validator.isEmail(formState[key])) {
        return "error";
      } else {
        return "success";
      }
    } else if (key === "phoneNumber") {
      if (
        formState[key].trim() === "" ||
        formState[key].length !== 10 ||
        !validator.isMobilePhone(formState[key])
      ) {
        return "error";
      } else {
        return "success";
      }
    }
    return "primary";
  };

  return (
    <Grid container spacing={2} marginTop={1}>
      {Object.keys(
        (({ email, phoneNumber }) => ({ email, phoneNumber }))(formState)
      ).map((key, i) => (
        <Grid key={i} item xs={12} sm={6}>
          <Input
            name={key}
            color={color(key)}
            label={label(key)}
            value={formState[key]}
            onChange={(e) => setFormState(e)}
            type={key === "email" ? "email" : "tel"}
          />
        </Grid>
      ))}
    </Grid>
  );
};
