import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select as SelectMaterial,
} from "@mui/material";

type SelectProps = {
  name: string;
  value: string;
  label?: string;
  items: string[];
  onChange: (e: SelectChangeEvent) => void;
};

export const Select: React.FC<SelectProps> = ({
  name,
  value,
  items,
  label = "",
  onChange,
}) => {
  return (
    <FormControl fullWidth size="small">
      <InputLabel id={label.replace(/ /g, "-").toLowerCase()}>
        {label}
      </InputLabel>
      <SelectMaterial
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        id={label.replace(/ /g, "-").toLowerCase()}
        labelId={label.replace(/ /g, "-").toLowerCase()}
        style={{ borderRadius: 10 }}
      >
        {items.map((item, i) => (
          <MenuItem key={i} value={item}>
            {item}
          </MenuItem>
        ))}
      </SelectMaterial>
    </FormControl>
  );
};
