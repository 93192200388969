import React, { useContext, useState, useEffect } from "react";

import { Box, Typography, Grid, useMediaQuery, Theme } from "@mui/material";

import { Doctor } from "../../models/doctor";
import { Hospital } from "../../models/hospital";

import { capitalize } from "../../helpers/regex";

import { Fields } from "../steps/Fields";
import { Address } from "../steps/Address";
import { Contact } from "../steps/Contact";
import { Specialties } from "../steps/Specialties";
import { Information } from "../steps/Information";

import { StepperActions } from "../actions/StepperActions";

import { FormContext } from "../../contexts/FormContext";
import { FirestoreContext } from "../../contexts/FirestoreContext";

type StepperFormProps = {
  step: number;
  onHandleBack: () => void;
  onHandleNext: () => void;
};

export const StepperForm: React.FC<StepperFormProps> = ({
  step,
  onHandleBack,
  onHandleNext,
}) => {
  const tablet = useMediaQuery(({ breakpoints }: Theme) =>
    breakpoints.down("md")
  );
  const {
    onHandleSaveDoctor,
    onHandleSaveHospital,
    state: { saved, element },
  } = useContext(FirestoreContext);
  const { address, formState, specialties } = useContext(FormContext);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (step === 4 && element) {
      onHandleNext();
    }
  }, [element]);

  const title = (): string => {
    if (step === 1) {
      return "Nivel Hospitalario";
    } else if (step === 2) {
      return "Especialidades";
    } else if (step === 3) {
      return "Dirección";
    } else if (step === 4) {
      return "Contacto";
    }
    return "Información Personal";
  };

  const getStepContent = (): JSX.Element => {
    switch (step) {
      case 1:
        return <Fields onDisabled={setDisabled} />;
      case 2:
        return <Specialties onDisabled={setDisabled} />;
      case 3:
        return <Address onDisabled={setDisabled} />;
      case 4:
        return <Contact onDisabled={setDisabled} />;
      default:
        return <Information onDisabled={setDisabled} />;
    }
  };

  const onHandleSave = (): void => {
    if (step === 4) {
      const { type } = formState;
      const { municipio, estado } = address!;
      if (type === "Hospital") {
        const {
          key,
          name,
          zip,
          email,
          street,
          phoneNumber,
          outdoorNumber,
          indoorNumber,
          neighborhood,
        } = formState;
        const hospital: Hospital = {
          key,
          name,
          address: {
            zip,
            indoorNumber,
            neighborhood,
            state: estado,
            outdoorNumber,
            city: municipio,
            street: capitalize(street),
          },
          contact: {
            email,
            phoneNumber,
          },
        };
        onHandleSaveHospital(hospital, specialties);
      } else if (type === "Médico") {
        const {
          key,
          name,
          zip,
          email,
          street,
          prefix,
          lastName,
          phoneNumber,
          outdoorNumber,
          indoorNumber,
          neighborhood,
        } = formState;
        const doctor: Doctor = {
          key,
          name,
          prefix,
          lastName,
          address: {
            zip,
            indoorNumber,
            neighborhood,
            state: estado,
            outdoorNumber,
            city: municipio,
            street: capitalize(street),
          },
          contact: {
            email,
            phoneNumber,
          },
        };
        onHandleSaveDoctor(doctor, specialties);
      }
    } else {
      onHandleNext();
    }
  };

  return (
    <Box>
      <Typography
        fontSize={20}
        color="primary"
        fontWeight={"bold"}
        gutterBottom
      >
        {title()}
      </Typography>
      {getStepContent()}
      <Grid container alignItems={"baseline"} marginTop={2}>
        <Grid item xs={3}>
          <Typography
            fontWeight={"bold"}
            fontSize={tablet ? 10 : 12}
            color={"primary"}
          >
            * Campo obligatorio.
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <StepperActions
            step={step}
            loading={saved}
            disabled={!disabled}
            onHandleBack={onHandleBack}
            onHandleNext={onHandleSave}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
