import React from "react";

import { Box, Button } from "@mui/material";

type SuccessActionsProps = {
  onClick: () => void;
};

export const SuccessActions: React.FC<SuccessActionsProps> = ({ onClick }) => {
  return (
    <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
      <Button variant="contained" onClick={onClick} sx={{ mt: 3, ml: 1 }}>
        {"Volver a registrar"}
      </Button>
    </Box>
  );
};
