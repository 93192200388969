import { useState } from "react";

export const useForm = <T extends Object>(initialState: T): any[] => {
  const [values, setValues] = useState<T>(initialState);

  const reset = (newValues = initialState) => setValues(newValues);

  const handleInputChange = <K extends Object>({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [target.name as keyof T]: target.value as keyof K });
  };

  return [values, handleInputChange, reset];
};
