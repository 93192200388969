import { ThemeOptions } from "@mui/material";

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#003C71",
    },
    secondary: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: ["Encode Sans"].join(","),
  },
};
