import React, { useState, useContext, useEffect } from "react";

import validator from "validator";

import { CircularProgress, Grid } from "@mui/material";

import { Input } from "../inputs/Input";
import { Select } from "../inputs/Select";

import DATA_ZIP from "../../data/data.json";

import { useDebounce } from "../../hooks/useDebounce";

import { FormContext } from "../../contexts/FormContext";

import { StepperProps } from "../../models/stepper";
import { IndexedDirection } from "../../models/direction";

import { isValidObjectName } from "../../helpers/regex";

export const Address: React.FC<StepperProps> = ({ onDisabled }) => {
  const context = useContext(FormContext);
  const [debounce, setDebounce] = useState<boolean>(false);

  const { address, formState, setFormState, onHandleAddress } = context;

  const { zip } = formState;

  useEffect(() => {
    if (zip.trim() === "") {
      setDebounce(false);
      onHandleAddress(null);
    }
  }, [zip]);

  useEffect(() => onDisabled(isFormValid()), [formState, onDisabled]);

  const onChangeText = (): void => {
    if (zip.trim() === "") return;
    const data: IndexedDirection = { ...DATA_ZIP };
    const filter = data[zip];
    onHandleAddress(filter);
    setDebounce(false);
  };

  useDebounce(onChangeText, 1000, [zip]);

  const isFormValid = (): boolean => {
    const { zip, street, outdoorNumber, neighborhood } = formState;
    if (zip.trim() === "" || !validator.isPostalCode(zip, "MX")) {
      return false;
    } else if (street.trim() === "" || !isValidObjectName(street)) {
      return false;
    } else if (
      outdoorNumber.trim() === "" ||
      !validator.isNumeric(outdoorNumber)
    ) {
      return false;
    } else if (neighborhood.trim() === "") {
      return false;
    }
    return true;
  };

  const label = (key: string): string => {
    if (key === "street") {
      return "Calle*";
    } else if (key === "outdoorNumber") {
      return "Número exterior*";
    } else if (key === "indoorNumber") {
      return "Número interior";
    } else if (key === "neighborhood") {
      return "Colonia o Fraccionamiento";
    }
    return "Código postal*";
  };

  const color = (
    key: string
  ): "error" | "primary" | "secondary" | "info" | "success" | "warning" => {
    if (key === "zip") {
      if (!validator.isPostalCode(formState[key], "MX")) {
        return "error";
      } else {
        return "success";
      }
    } else if (key === "street") {
      if (formState[key].trim() === "" || !isValidObjectName(formState[key])) {
        return "error";
      } else {
        return "success";
      }
    } else if (key === "outdoorNumber") {
      if (
        formState[key].trim() === "" ||
        !validator.isNumeric(formState[key])
      ) {
        return "error";
      } else {
        return "success";
      }
    } else if (key === "indoorNumber") {
      if (formState[key].trim() === "") {
        return "primary";
      } else if (!validator.isNumeric(formState[key])) {
        return "error";
      } else {
        return "success";
      }
    }
    return "primary";
  };

  return (
    <Grid container spacing={2} marginTop={1}>
      {Object.keys((({ zip }) => ({ zip }))(formState)).map((key, i) => (
        <Grid key={i} item xs={12}>
          <Input
            name={key}
            color={color(key)}
            label={label(key)}
            value={formState[key]}
            onChange={(e) => {
              setFormState(e);
              setDebounce(true);
            }}
            endAdornment={
              debounce && <CircularProgress color={"primary"} size={24} />
            }
          />
        </Grid>
      ))}
      {address &&
        Object.keys(
          (({ street, outdoorNumber, indoorNumber, neighborhood }) => ({
            street,
            outdoorNumber,
            indoorNumber,
            neighborhood,
          }))(formState)
        ).map((key, i) => (
          <Grid
            item
            key={i}
            xs={12}
            sm={key === "street" || key === "neighborhood" ? 12 : 6}
          >
            {key === "neighborhood" ? (
              <Select
                name={key}
                label={label(key)}
                value={formState[key]}
                items={address.colonias}
                onChange={(e) => setFormState(e)}
              />
            ) : (
              <Input
                name={key}
                color={color(key)}
                label={label(key)}
                value={formState[key]}
                onChange={(e) => setFormState(e)}
                disabled={key === "city" || key === "state"}
              />
            )}
          </Grid>
        ))}
      {address &&
        Object.keys(
          (({ estado, municipio }) => ({ estado, municipio }))(address)
        ).map((key, i) => (
          <Grid key={i} item xs={12} sm={6}>
            <Input
              disabled
              name={key}
              color={"primary"}
              value={address[key] as string}
              label={i === 0 ? "Estado*" : "Ciudad*"}
            />
          </Grid>
        ))}
    </Grid>
  );
};
