import React, { useContext, useEffect } from "react";

import {
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Radio,
} from "@mui/material";

import { StepperProps } from "../../models/stepper";

import { FormContext } from "../../contexts/FormContext";
import { FirestoreContext } from "../../contexts/FirestoreContext";

export const Specialties: React.FC<StepperProps> = ({ onDisabled }) => {
  const {
    state: { specialties },
  } = useContext(FirestoreContext);
  const { onHandleToggle, specialties: values } = useContext(FormContext);

  useEffect(() => onDisabled(isFormValid()), [values, onDisabled]);

  const isFormValid = (): boolean => {
    if (values.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <Grid container spacing={1} marginTop={2}>
      {specialties.map((specialty, i) => (
        <Grid key={i} item md={4} sm={12}>
          <ListItem
            key={specialty.uid}
            disablePadding
            style={{ border: "1px solid #e8eaf6", borderRadius: 15 }}
          >
            <ListItemButton
              dense
              role={undefined}
              onClick={() => onHandleToggle(specialty)}
            >
              <ListItemIcon>
                <Radio
                  size="small"
                  checked={values.some((el) => el.uid === specialty.uid)}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                id={specialty.uid}
                primary={
                  <Typography fontSize={12} fontWeight={"bold"}>
                    {specialty.name}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </Grid>
      ))}
    </Grid>
  );
};
