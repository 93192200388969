import { Box, Typography } from "@mui/material";

const Copyright = () => {
  return (
    <Typography variant="body2" color="white" textAlign={"start"} marginTop={2}>
      {"Copyright © Plan Seguro "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const Footer = () => {
  return (
    <>
      <Box
        height={2}
        width={"100%"}
        style={{ backgroundColor: "#BCC3CC" }}
        marginTop={10}
      />
      <Copyright />
    </>
  );
};
